import {
  CONTENTFUL_TEXT_COLOUR_MAPPER,
  CONTENTFUL_TEXT_SIZE_MAPPER,
  isVariantColor,
} from '@/siteBuilder/utils/text/mappers'
import { mapSpacer } from 'src/siteBuilder/renderer/contentful/mapSpacer'
import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

import type { ITextFields } from 'contentful-types'
import Text, { type TextProps } from './Text'

const ContentfulText = withContentful<ITextFields, TextProps>(Text, {
  spacer: mapSpacer,
  size: ({ fields: { size } }) => CONTENTFUL_TEXT_SIZE_MAPPER[size],

  colour: ({ fields: { colour } }) => {
    if (isVariantColor(colour)) {
      return colour
    }
    return CONTENTFUL_TEXT_COLOUR_MAPPER[colour]
  },
  align: ({ fields: { align } }) => align?.toLocaleLowerCase() as Lowercase<typeof align>,
})
export default ContentfulText
